.report {
  min-height: 90vh;
  background: var(--white-2);
  padding: 0 1.5em 6em 1.5em;
  width: calc(100% - 3em);
  max-width: calc(430px - 3em);
  section {
    margin-top: 1.5em;
  }
}

// @media only screen and (min-width: 721px) {
//   .report {
//     max-width: calc(100% - 3em);
//   }
// }

.view {
  width: 100vw;
  max-width: 430px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: #7d7d7d;

  // @media only screen and (min-width: 721px) {
  //   & {
  //     max-width: 100%;
  //   }
  // }

  .viewArea {
    // height:calc(100vh - 106px);
    overflow-y: auto;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-top: 16px;
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
}
