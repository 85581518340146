*,
body {
  margin: 0;
  padding: 0;
  border: 0;
  color: var(--black);
  letter-spacing: -0.3px;
  font-weight: 400;
  font-family: "Noto Sans KR", sans-serif;
}

.ja {
  font-family: "Noto Sans JP", sans-serif;
}

// Apply English or Korean font
.en,
.ko {
  font-family: "Noto Sans KR", sans-serif;
}

body {
  user-select: none;
  max-width: 430px;
  margin: 0 auto;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0);
}

// @media only screen and (min-width: 721px) {
//   body {
//     max-width: 100%;
//     width: 100%; // 예: 태블릿 기준으로 확장
//   }
// }

#root {
  background: var(--white-2);
}

.App {
}

.routerElement {
  background: var(--white-2);
  // min-height:100vh;
}

.element {
  background: #ebebeb;
}

main {
  min-height: 100vh;
}

li {
  list-style: none;
}

button {
  background-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selectBackWhite {
  select {
    border: 1px solid var(--black-01, rgba(0, 0, 0, 0.1));
    padding: 9.5px 13px;
    border-radius: 4px;
    font-size: 0.75em;
    font-weight: 400;
    line-height: 133.333%;
    height: 100%;
    &:focus {
      outline-color: var(--primary-mobile, #66b6ff);
    }
  }
}

.VERY_SERIOUS {
  background: var(--graph-violet, #9e048e) !important;
}
.LIGHT {
  background: var(--graph-yellow, #faff00) !important;
  color: var(--black);
}
.SERIOUS {
  background: var(--graph-red, #ff2128) !important;
}
.SEVERITY {
  background: var(--graph-orange, #f90) !important;
}
.NORMAL {
  background: var(--graph-green, #1eda00) !important;
}
.default {
  background: var(--dark-grey-50, #8a8a8a) !important;
}

.webState {
  position: absolute;
  top: 20px;
  margin: 0 auto;
  padding: 0 1em;
  max-width: 430px;
}

// @media only screen and (min-width: 721px) {
//   .webState {
//     max-width: 100%;
//     width: initial; // 예: 태블릿 기준으로 확장
//   }
// }

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loadingBack {
  background: linear-gradient(
    110deg,
    var(--mode-opacity-30) 8%,
    var(--mode-opacity-40) 18%,
    var(--mode-opacity-30) 33%
  );
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 2s shine linear infinite;
}

@keyframes up {
  0% {
    top: 100vh;
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.openUp {
  top: 0;
  animation: 0.5s up linear;
}

#kmcIframeWrap {
  background: var(--white-2);
}

@media only screen and (min-width: 680px) {
  body {
    font-size: 1rem;
  }
}

// @media only screen and (min-width:768px){
//   body{
//     font-size:1.5rem;
//   }
// }

// @media only screen and (min-width:1080px){
//   body{
//     font-size:2rem;
//   }
// }

.ant-popover-title div {
  font-weight: 500;
  font-size: 13px;
}

li.textList {
  font-size: 13px;
}
