.header {
  width: calc(100% - 2rem);
  max-width: calc(430px - 2rem);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-bottom: 1px solid var(--black-005);
  background-color: var(--white-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 1rem 16px;

  // @media only screen and (min-width: 721px) {
  //   & {
  //     max-width: calc(100% - 3em);
  //     width: calc(100% - 3em);
  //   }
  // }

  h1 {
    margin-left: 16px;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 122.222%;
    flex: 1;

    &.tablet {
      text-align: center;
      margin-left: 0;
    }
  }
  .pageNav {
    display: flex;
    align-items: center;
    gap: 1rem;
    h2 {
      a {
        text-align: center;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 122.222%;
        display: block;
        min-width: 53px;
      }

      &.on a {
        color: var(--Black, #000);
      }
      &.off a {
        color: var(--black-04, rgba(0, 0, 0, 0.4));
      }
    }
  }
  .icon {
    display: flex;
  }

  .select {
    width: 97px;
    height: 20px;
    select {
      padding-right: 24px;
      option {
        text-align: center;
      }
    }
    svg {
      // width:12px;
      // height:12px;
    }
  }
  &.closeHeader {
    background-color: var(--white-08);
    h1 {
      flex: 1;
      margin-left: 1rem;
    }
  }
}

.backHeader {
  h1 {
    text-align: center;
  }
  .backIcon {
    width: 2rem;
    height: 2rem;
    background-color: red;
    position: fixed;
    top: 0.1rem;
    margin-left: 1rem;
  }
  .close {
  }
}
